import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import RequestTypes from "../../enums/RequestTypes";
import RequestStatus from "../../enums/RequestStatus";
import { createRequest } from "../../actions/requests/requests";
import DateUtil from "../../util/DateUtil";
import Roles from "../../enums/Roles";
import EquipmentOverview from "./EquipmentOverview";
import CustomLabel from "../sub/CustomLabel";
import { Modal } from "react-bootstrap";
import TextareaCounter from 'react-textarea-counter';

class InterventionRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      material_type_id: "",
      precisions: "",
      urgent: false,
      desiredDate: "",
      client_id: null,
      author_id: null,
      nameError: null,
      disabled: false,
    };

    if (this.props.request) {
      this.state = {
        material_type_id: this.props.request.material_type_id,
        precisions: this.props.request.precisions,
        urgent: this.props.request.urgent,
        desiredDate: DateUtil.toyyyyMMdd(this.props.request.desired_date),
        disabledInputs: true,
        nameError: null,
        disabled: false,
      };
    }
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  giveAuthorId() {
    let user = this.props.user;

    if (user.role === Roles.ADMIN || user.role === Roles.SALES_REP)
      return user._id;
    return null;
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    var data = {
      request_type: RequestTypes.INTERVENTION,
      // TODO change the id
      material_type_id: this.props.equipment.material_type_id,
      equipment_id: this.props.equipment._id,
      precisions: this.state.precisions,
      urgent: this.state.urgent,
      desired_date: this.state.desiredDate,
      client_id: this.state.client_id,
      author_id: this.giveAuthorId(),
      status: RequestStatus.NEW,
    };

    this.setState({ disabled: true });

    this.props.onCreateRequest(data, () => this.props.close());
  }

  onDateChange(value) {
    var today = new Date();
    var desiredDate = new Date(value);
    var error = null;
    if (today >= desiredDate) {
      error = <FormattedMessage id="DesiredDate.Superior.TodayDate" />;
      this.setState({ desiredDate: value, nameError: error, disabled: true });
    } else {
      this.setState({ desiredDate: value, nameError: error, disabled: false });
    }
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.desiredDate) ||
      ((this.props.authorRole === Roles.ADMIN ||
        this.props.authorRole === Roles.SALES_REP) &&
        !this.state.client_id)
    );
  }

  onChange(key, value) {
    if (key === "urgent") this.setState({ [key]: !this.state.urgent });
    else this.setState({ [key]: value });
  }

  render() {
    var switchClassName = "switch-bg-green";
    if (
      this.props.user.role === Roles.SALES_REP ||
      this.props.user.role === Roles.ADMIN
    )
      switchClassName = "switch-bg-blue";

    var clientSelectOptions;
    if (
      this.props.authorRole === Roles.ADMIN ||
      this.props.authorRole === Roles.SALES_REP
    ) {
      if (this.props.establishment) {
        clientSelectOptions = this.props.clients.map((client) => {
          if (client.establishment_id === this.props.establishment._id) {
            return (
              <option key={client._id} value={client._id}>
                {client.name} {client.first_name}
              </option>
            );
          } else return null;
        });
      } else if (this.props.establishmentId) {
        clientSelectOptions = this.props.clients.map((client) => {
          if (client.establishment_id === this.props.establishmentId) {
            return (
              <option key={client._id} value={client._id}>
                {client.name} {client.first_name}
              </option>
            );
          } else return null;
        });
      }
    }

    return (
      <React.Fragment>
        <EquipmentOverview equipment={this.props.equipment} />
        <div className="form-group row align-items-center">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Urgent" })}
            htmlFor="urgent"
            labelClassName="col-12 col-sm-5"
            required={this.props.required}
          />
          <div className="col-12 col-sm-7">
            <div className="text-left custom-control custom-switch switch-primary">
              <input
                type="checkbox"
                className={"custom-control-input " + switchClassName}
                id="urgent"
                checked={this.state.urgent}
                onChange={(e) => this.onChange("urgent", e.target.value)}
                disabled={this.state.disabledInputs}
              />
              <CustomLabel
                htmlFor="urgent"
                labelClassName="custom-control-label"
              />
            </div>
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Desired.Date" })}
            htmlFor="desiredDate"
            required={this.props.required}
          />
          <div className="col-12 col-sm-7">
            <input
              type="date"
              className="form-control"
              id="desiredDate"
              value={this.state.desiredDate}
              onChange={(e) => this.onDateChange(e.target.value)}
              disabled={this.state.disabledInputs}
            />
            <div className="text-danger">
              <small>{this.state.nameError}</small>
            </div>
          </div>
        </div>
        {(this.props.authorRole === Roles.ADMIN ||
          this.props.authorRole === Roles.SALES_REP) && (
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Client.Select" })}
                htmlFor="clientSelect"
                required={this.props.required}
              />
              <div className="col-12 col-sm-7">
                <select
                  id="clientSelect"
                  className="form-control"
                  onChange={(e) => this.onChange("client_id", e.target.value)}
                >
                  <option></option>
                  {clientSelectOptions}
                </select>
              </div>
            </div>
          )}
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Precisions" })}
            htmlFor="precisions"
            required={this.props.required}
          />
          <div className="col-12 col-sm-7">
            <TextareaCounter
              countLimit={500}
              className="textareaCounter"
              id="precisions"
              value={this.state.precisions}
              onChange={(e) => this.onChange("precisions", e.target.value)}
              disabled={this.state.disabledInputs}
            />
          </div>
        </div>

        {!this.state.disabledInputs && (
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => this.props.close()}
            >
              <FormattedMessage id="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Create" />
            </button>
          </Modal.Footer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    user: state.user,
    clients: state.clients,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(InterventionRequest));
