import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import APIUrl from "../../../APIUrl";
import Util from "../../../util/Util";
import {
  updateRequest,
  addRequestFiles,
  deleteRequestFile,
} from "../../../actions/requests/requests";
import { hasAlreadyRequest } from "../../../actions/equipments/equipments";
import RequestTypes from "../../../enums/RequestTypes";
import RenewalRequest from "../RenewalRequest";
import InterventionRequest from "../InterventionRequest";
import CustomLabel from "../../sub/CustomLabel";
import Roles from "../../../enums/Roles";
import EquipmentUtil from "../../../util/EquipmentUtil";
import ArrayUtil from "../../../util/ArrayUtil";
import EstablishmentRequestUtil from "../../../util/EstablishmentRequestUtil";
import EqOwner from "../../../enums/EqOwner";
import Select from "react-select";
import CustomFileDropZone from "../../sub/CustomFileDropZone";
import { getEstablishmentEquipments } from "../../../actions/equipments/equipments";
import RequestStatus from "../../../enums/RequestStatus";
import { Modal } from "react-bootstrap";
import TextareaCounter from 'react-textarea-counter';
class RequestModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    if (this.props.request) {
      this.state = {
        disabled: this.props.request.status === RequestStatus.DONE || false,
        response: this.props.request.response || "",
      };
    } else {
      this.state = {
        disabled: false,
        response: "",
        establishmentId:
          (this.props.equipment && this.props.equipment.establishment_id) || "",
        selectedBrand:
          (this.props.equipment && this.props.equipment.brand_id) || "",
        selectedModel:
          (this.props.equipment && this.props.equipment.model_id) || "",
        selectedEquipment:
          (this.props.equipment && this.props.equipment._id) || "",
        hasAlreadyRequest: false,
      };
    }
  }

  hasAlreadyRequest(equipmentId) {
    this.props.onHasAlreadyRequest(
      equipmentId,
      null,
      (result) => this.setState({ hasAlreadyRequest: result })
    );
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  update(request) {
    var response = this.state.response;

    var data = {
      requestId: request._id,
      updatedField: "response",
      updatedValue: response,
    };

    this.props.onUpdateRequest(data, () => { });
  }

  hasEmptyField() {
    return Util.emptyString(this.state.response);
  }

  onChange(key, value) {
    this.setState({ [key]: value });
    if (key === "selectedEquipment") this.hasAlreadyRequest(value);
  }

  selectEstablishment(establishmentId) {
    this.setState({
      establishmentId: establishmentId,
      selectedBrand: "",
      selectedModel: "",
    });
    this.props.onGetEstablishmentEquipments(establishmentId);
  }

  selectBrand(brandId) {
    this.setState({
      selectedBrand: brandId,
      selectedModel: "",
      selectedEquipment: "",
    });
  }

  selectModel(modelId) {
    this.setState({ selectedModel: modelId, selectedEquipment: "" });
  }

  getEquipments() {
    let filteredEquipments = [];
    for (var equipment of this.props.equipments) {
      if (
        equipment.owner === EqOwner.DM_FROM_PSDM ||
        equipment.owner === EqOwner.INTERNAL_EHPAD
      ) {
        if (
          equipment.brand_id === this.state.selectedBrand &&
          equipment.model_id === this.state.selectedModel
        ) {
          filteredEquipments.push(equipment);
        }
      }
    }
    return filteredEquipments;
  }

  getBrands() {
    let filteredBrands = [];
    for (let i = 0; i < this.props.equipments.length; i++) {
      for (let y = 0; y < this.props.brands.length; y++) {
        if (
          this.props.equipments[i].owner === EqOwner.DM_FROM_PSDM ||
          this.props.equipments[i].owner === EqOwner.INTERNAL_EHPAD
        ) {
          if (
            this.props.equipments[i].brand_id.toString() ===
            this.props.brands[y]._id.toString()
          ) {
            if (!filteredBrands.includes(this.props.brands[y])) {
              filteredBrands.push(this.props.brands[y]);
            }
          }
        }
      }
    }
    return filteredBrands.sort((a, b) =>
      ArrayUtil.ascendingSort(a.name, b.name)
    );
  }

  getModels() {
    let filteredModels = [];
    for (let i = 0; i < this.props.equipments.length; i++) {
      for (let y = 0; y < this.props.models.length; y++) {
        if (
          this.props.equipments[i].owner === EqOwner.DM_FROM_PSDM ||
          this.props.equipments[i].owner === EqOwner.INTERNAL_EHPAD
        ) {
          if (
            this.props.equipments[i].model_id.toString() ===
            this.props.models[y]._id.toString()
          ) {
            if (this.props.models[y].brand_id === this.state.selectedBrand) {
              if (!filteredModels.includes(this.props.models[y])) {
                filteredModels.push(this.props.models[y]);
              }
            }
          }
        }
      }
    }
    return filteredModels.sort((a, b) =>
      ArrayUtil.ascendingSort(a.name, b.name)
    );
  }

  render() {
    if (this.props.equipment) {
      var clientRequest = null;
      var requestType = null;

      switch (this.props.requestType) {
        case RequestTypes.RENEWAL:
          requestType = "Renewal";
          clientRequest = (
            <RenewalRequest
              request={this.props.request}
              equipment={this.props.equipment}
              user={this.props.user}
              materialsTypes={this.props.materialsTypes}
              required={false}
              close={() => this.props.close()}
            />
          );
          break;
        case RequestTypes.INTERVENTION:
          requestType = "Intervention";
          clientRequest = (
            <InterventionRequest
              request={this.props.request}
              equipment={this.props.equipment}
              user={this.props.user}
              materialsTypes={this.props.materialsTypes}
              close={() => this.props.close()}
              required={false}
            />
          );
          break;
        default:
          console.log("Error requestType");
      }
    } else {
      let equipment = EquipmentUtil.getEquipment(
        this.state.selectedEquipment,
        this.props.equipments
      );
      let establishment = EstablishmentRequestUtil.getEstablishment(
        this.state.establishmentId,
        this.props.establishments
      );
      clientRequest = (
        <InterventionRequest
          request={this.props.request}
          equipment={equipment}
          establishment={establishment}
          authorRole={Roles.ADMIN}
          user={this.props.user}
          materialsTypes={this.props.materialsTypes}
          close={() => this.props.close()}
          required={false}
        />
      );
    }

    if (this.props.establishments) {
      var establishmentsNode = this.props.establishments.map(
        (establishment) => {
          return {
            value: establishment._id,
            label: establishment.name,
          };
        }
      );

      if (this.state.establishmentId) {
        let filteredBrands = this.getBrands();
        var brandsNode = filteredBrands.map((brand) => {
          return {
            value: brand._id,
            label: brand.name,
          };
        });

        if (this.state.selectedBrand !== "") {
          let filteredModels = this.getModels();

          var modelsNode = filteredModels.map((model) => {
            return {
              value: model._id,
              label: model.name,
            };
          });

          if (this.state.selectedModel !== "") {
            let filteredEquipments = this.getEquipments();

            var equipmentsNode = filteredEquipments.map((equipment) => {
              return {
                value: equipment._id,
                label: equipment.internal_ref,
              };
            });
          }
        }
      }
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={
                this.props.requestType
                  ? "Request." + requestType
                  : "Request.Intervention"
              }
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!this.props.request && this.props.equipment && (
            <InterventionRequest
                isOpen={true}
                authorRole={Roles.ADMIN}
                establishmentId={this.props.equipment.establishment_id}
                equipment={this.props.equipment}
                equipments={this.props.equipments}
                establishments={this.props.establishments}
                close={() => this.props.close()}
              />
          )}
          {!this.props.establishment && !this.props.equipment && (
            <div className="col-6 m-3 mr-auto ml-auto">
              <Select
                onChange={(e) => this.selectEstablishment(e.value)}
                options={establishmentsNode}
                placeholder={this.props.intl.formatMessage({
                  id: "Select.Establishment",
                })}
              />
            </div>
          )}
          {this.state.establishmentId && !this.props.equipment && (
            <div className="container">
              <div className="row">
                <div className="col-md mb-2">
                  {this.state.establishmentId !== "" && (
                    <Select
                      onChange={(e) => this.selectBrand(e.value)}
                      options={brandsNode}
                      placeholder={this.props.intl.formatMessage({
                        id: "Select.Brand",
                      })}
                    />
                  )}
                </div>
                <div className="col-md mb-2">
                  {this.state.selectedBrand !== "" && (
                    <Select
                      onChange={(e) => this.selectModel(e.value)}
                      options={modelsNode}
                      placeholder={this.props.intl.formatMessage({
                        id: "Select.Model",
                      })}
                    />
                  )}
                </div>
                <div className="col-md mb-2">
                  {this.state.selectedBrand !== "" &&
                    this.state.selectedModel !== "" && (
                      <Select
                        onChange={(e) =>
                          this.onChange("selectedEquipment", e.value)
                        }
                        options={equipmentsNode}
                        placeholder={this.props.intl.formatMessage({
                          id: "Select.Equipment",
                        })}
                      />
                    )}
                </div>
              </div>
            </div>
          )}
          {!this.state.hasAlreadyRequest ? (
             <>{((this.props.request) || (!this.props.equipment)) && (<div>
              {(this.state.selectedEquipment || this.props.equipment) && (
                  <>{clientRequest}</>
                )}
              <hr />
              {(this.props.equipment) && (
                <CustomFileDropZone
                  files={this.props.request.files}
                  data={this.props.request}
                  userRole={this.props.user.role}
                  addFiles={(requestId, data, successCallback) =>
                    this.props.onaddRequestFiles(
                      requestId,
                      data,
                      successCallback
                    )
                  }
                  deleteFile={(data, successCallback) =>
                    this.props.ondeleteRequestFile(data, successCallback)
                  }
                  url={APIUrl.getRequestFile}
                  disabled={this.state.disabled}
                />
              )}
              {this.props.equipment && (
                <div className="form-group row mt-3">
                  <CustomLabel
                    label={this.props.intl.formatMessage({ id: "Response" })}
                    htmlFor="response"
                  />
                  <div className="col-12 col-sm-7">
                    <TextareaCounter
                      countLimit={500}
                      className="textareaCounter"
                      id="response"
                      value={this.state.response}
                      disabled={this.state.disabled}
                      onChange={(e) => this.onChange("response", e.target.value)}
                      onBlur={() => this.update(this.props.request)}
                    />
                  </div>
                </div>
              )}
            </div>
          )}</>
          ) : (
            <div className="alert alert-warning" role="alert">
              <FormattedMessage id="Intervention.Request.Already.Exist" />
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    user: state.user,
    requests: state.requests,
    brands: state.brands,
    models: state.models,
    equipments: state.equipments,
    establishments:state.establishments
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetEstablishmentEquipments: (establishmentId, successCallback) =>
      dispatch(getEstablishmentEquipments(establishmentId, successCallback)),
    onUpdateRequest: (data, successCallback) =>
      dispatch(updateRequest(data, successCallback)),
    onaddRequestFiles: (requestId, data, successCallback) =>
      dispatch(addRequestFiles(requestId, data, successCallback)),
    ondeleteRequestFile: (data, successCallback) =>
      dispatch(deleteRequestFile(data, successCallback)),
    onHasAlreadyRequest: (equipmentId, requestType, successCallback) =>
      dispatch(hasAlreadyRequest(equipmentId, requestType, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RequestModalAdmin));
